.register-medical-examination {
    .register-form {
        background-image: url('../../../svg/background_register_medical_exam.png');

        .register-form-inner {
            .css-nppuvv-MuiFormLabel-root-MuiInputLabel-root {
                @media screen and (max-width: 414px) {
                    font-size: 16px;
                }
            }
        }
    }
}