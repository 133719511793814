@media screen and (max-width: 1366px) {
  .vital-sign-item {
    padding-left: 0 !important;
    max-width: 49% !important;
    .vital-sign-item-input {
      font-size: 13px;
    }
  }
}
.vital-sign-item-adornment {
  margin-right: 0 !important;
}
