body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* min-width: 1280px; */
}

@media print {
  @page {
    size: 1000px 1290px;
    margin: 0.5cm 1cm;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cfd8dc !important;
}

p {
  margin: 0px;
}

.mce-editor-customs table {
  border-collapse: collapse;
  width: -webkit-fill-available;
}

.mce-editor-customs td {
  padding: 4px;
  background-color: unset;
  border: 0.5px solid;
}

.mce-editor-customs p {
  display: block;
  /* margin-block-start: 0.5em; */
  /* margin-block-end: 0.5em; */
}
