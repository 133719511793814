$color-white : #fff;

.therapy-detail {
  padding: 10px;
  background-color: #E0ECFF !important;
  padding-top: 0;
  margin-top: -16px;

  .flex-space-between {
    display: flex;
    justify-content: space-between;
  }

  .pd10 {
    padding: 10px;
  }

  .mb10 {
    margin-bottom: 10px;
  }

  .mb20 {
    margin-bottom: 20px;
  }

  .header-top {
    .icon-home {
      display: inline-block;
      margin-top: 5px;
    }

    .patient-name {
      color: #003CA6;
      font-weight: 500;
    }
  }

  .box-child {
    background: #fff;
    margin-top: 10px;

    .header {
      .info {
        p {
          font-size: 14px;
          color: #607D8B;

          span {
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
    }

    .title {
      font-weight: 500;
      color: #607D8B;
      text-transform: uppercase;
    }

    // thong tin tri lieu
    .therapy-session-info {
      .css-nppuvv-MuiFormLabel-root-MuiInputLabel-root {
        font-size: 16px;
      }

      table {
        background-color: #F4F8FF;

        thead {
          th {
            color: #607D8B;
            font-size: 16px;
            border-bottom: 0;
            padding: 0 16px;
          }
        }

        tbody {
          td {
            font-size: 16px;
          }
        }
      }
    }

    // chi dinh
    .therapy-indication {
      label {
        font-size: 16px;
      }

      td {
        font-size: 14px;

        p {
          font-size: 14px;
        }
      }
    }

    // thuoc su dung
    .drug-use {

      h6,
      label {
        font-size: 16px;
      }

      .css-1vh6nji {
        padding: 8px;
        border-top: 4px solid white;
        background-color: #F4F8FF;
        margin-left: 16px;
        line-height: 24px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-size: 14px;
        letter-spacing: 0.01071em;
      }
    }

    // vat tu y te
    .medical-supplies {
      label {
        font-size: 16px;
      }

      td {
        font-size: 14px;

        p {
          font-size: 14px;
        }
      }
    }

    // ket qua buoi tri lieu
    .therapy-sesion-result {
      font-size: 14px;
    }
  }
}