.tablePrint {
  td {
    font-size: 20px;
    padding: 10px 8px !important;
    p{
      font-size: 20px;
    }
  }
  tr {
    font-size: 20px;
    th{
      padding: 10px 8px !important;
      span{
        font-size: 16px;
      }
    }
  }
}

.info-a5-size{
  font-size: 20px !important;
  margin: 6px 0px;
}