.receive-wizard-dialog {

    .css-19midj6,
    .css-1k79ou {
        padding: 15px 45px;
    }

    .box-info {
        padding-left: 15px;
        border-left: 2px solid hsla(0, 0%, 85%, .6);

        .contact-info-person {
            margin-bottom: 0;
        }

        h6 {
            color: #455a64;
            font-size: 18px;
        }

        .label-age-encounter {
            >label {
                visibility: hidden;
            }
        }
    }
}