.slider-padding {
  padding: 1px;
}

.slick-list {
  padding: 1px;
  overflow: visible;
}

.slick-slide div {
  outline: none;
}
